export const states = {

    forest1: {
        videoId: 1448735,
        fileNumber: 2,
        option1: 'rain2',
        option2: 'beach2',
        option1Label: 'make it rain',
        option2Label: 'go to the beach',
        description: "Imagine yourself in a calm forest. Find a comfortable position, and let yourself relax.\nTune into your sense of touch, and notice where your body is connected to your surroundings. Soften any tension you've been holding. \n When you're ready, choose what to do next.",
        audio: "rain",
        url: "https://www.pexels.com/video/video-of-forest-1448735/",
        author: "Ruvim Miksanskiy"
    },
    rain2: {
        videoId: 5155680,
        fileNumber: 0,
        option1: 'rain_again3',
        option2: 'meadow3',
        option1Label: 'stay in the rain',
        option2Label: 'lie in a meadow',
        description: "Let the rain wash over you as you focus on your breathing.\nNotice where your breath originates, as you breathe in and out. Keep breathing for a while, paying attention to the source of that breath. \n When you're ready, choose where to go next.",
        audio: "rain",
        url: "https://www.pexels.com/video/trees-getting-watered-by-the-rainwatered-5155680/",
        author: "David Roberts"
    },
    beach2: {
        videoId: 1757800,
        fileNumber: 4,
        option1: 'clouds3',
        option2: 'waterfall3',
        option1Label: 'look at the clouds',
        option2Label: 'visit a waterfall',
        description: "Let the waves wash over you as you focus on your breathing.\nNotice where your breath originates, as you breathe in and out. Keep breathing for a while, paying attention to the source of that breath. \n When you're ready, choose where to go next.",
        audio: "beach",
        url: "https://www.pexels.com/video/waves-rushing-to-the-shore-1757800/",
        author: "Engin Akyurt"
    },
    rain_again3: {
        videoId: 3603737,
        fileNumber: 3,
        option1: 'sunset4',
        option2: 'stargazing4',
        option1Label: 'watch the sunset',
        option2Label: 'go stargazing',
        description: "As you continue to breathe, you may notice your mind wandering to other thoughts.\nThis is okay. Gently redirect your awareness back to your breathing.\nWe'll stay like this for some time, noticing our breath and letting our bodies relax. \n When you're ready, choose where to go next.",
        audio: "rain2",
        url: "https://www.pexels.com/video/wet-trees-brought-by-rainfall-3603737/",
        author: "Aduri Prem Kumar"
    },
    meadow3: {
        videoId: 857046,
        fileNumber: 2,
        option1: 'sunset4',
        option2: 'stargazing4',
        option1Label: 'watch the sunset',
        option2Label: 'go stargazing',
        description: "As you continue to breathe, you may notice your mind wandering to other thoughts.\nThis is okay. Gently redirect your awareness back to your breathing.\nWe'll stay like this for some time, noticing our breath and letting our bodies relax. \n When you're ready, choose where to go next.",
        audio: "hummingbird",
        url: "https://www.pexels.com/video/spring-meadow-with-grass-and-flowers-857046/",
        author: "Ella Pix"
    },
    clouds3: {
        videoId: 857041,
        fileNumber: 2,
        option1: 'sunset4',
        option2: 'stargazing4',
        option1Label: 'watch the sunset',
        option2Label: 'go stargazing',
        description:  "As you continue to breathe, you may notice your mind wandering to other thoughts.\nThis is okay. Gently redirect your awareness back to your breathing. \n We'll stay like this for some time, noticing our breath and letting our bodies relax.\nWhen you're ready, choose where to go next.",
        audio: "sunnyDay",
        url: "https://www.pexels.com/video/footage-of-sunlight-857041/",
        author: "Ella Pix"
    },
    waterfall3: {
        videoId: 1722694,
        fileNumber: 5,
        option1: 'sunset4',
        option2: 'stargazing4',
        option1Label: 'watch the sunset',
        option2Label: 'go stargazing',
        description:  "As you continue to breathe, you may notice your mind wandering to other thoughts.\nThis is okay. Gently redirect your awareness back to your breathing.\nWe'll stay like this for some time, noticing our breath and letting our bodies relax. \n When you're ready, choose where to go next.",
        audio: "waterfall",
        url: "https://www.pexels.com/video/waterfalls-flowing-1722694/",
        author: "Engin Akyurt"
    },
    sunset4: {
        videoId: 5754040,
        fileNumber: 1,
        option1: null,
        option2: null,
        option1Label: null,
        option2Label: null,
        description: "Before you return to what you were doing before this, take one more moment of self-awareness. \n You are made of so much strength and energy. \n Tell yourself, thank you, for taking this small break today. \n When you're ready, click the scene to exit.",
        audio: "crickets",
        url: "https://www.pexels.com/video/horizon-in-the-sunset-5754040/",
        author: "Al d'Vilas"
    },
    stargazing4: {
        videoId: 857251,
        fileNumber: 0,
        option1: null,
        option2: null,
        option1Label: null,
        option2Label: null,
        description: "Before you return to what you were doing before this, take one more moment of self-awareness. \n You are made of so much strength and energy. \n Tell yourself, thank you, for taking this small break today. \n When you're ready, click the scene to exit.",
        audio: "crickets",
        url: "https://www.pexels.com/video/beautiful-timelapse-of-the-night-sky-with-reflections-in-a-lake-857251/",
        author: "eberhard grossgasteiger"
    },
    end: {
        option1Label: "restart",
        option2Label: "donate"
    }
}