import { createClient } from 'pexels';
import './App.css';
import forest from './assets/forest.jpg'
import rain from './assets/audio/rain.mp3'
import rain2 from './assets/audio/rainAndCrickets.mp3'
import beach from './assets/audio/beach.mp3'
import hummingbird from './assets/audio/hummingbird.mp3'
import sunnyDay from './assets/audio/sunnyDay.mov'
import waterfall from './assets/audio/waterfall.mov'
import crickets from './assets/audio/cricketsAtNight.mp3'
import loading from './assets/loading.gif'

import React, { useRef, useState, useEffect } from 'react'

const App = () => {
  const [currentState, setCurrentState] = useState('')
  const [url, setUrl] = useState("");
  const [beginning, setBeginning] = useState(true);
  const [step, setStep] = useState(0);
  let states = require('./video-ids.js');

  //PEXELS CLIENT______________________
  const client = createClient('563492ad6f91700001000001d5080b8c327c48a98026dfc21af0ba5f');

  //get new video every time currentState changes
  useEffect(() => {
    //get video file from Pexels API
    const getVid = (vidId, fileNumber) => {
      return client.videos.show({ id: vidId }).then(video => {
        return video.video_files[fileNumber].link;
      });
    }

    if (currentState !== "" && currentState !== "end") {
      getVid(states.states[currentState].videoId, states.states[currentState].fileNumber).then(url => setUrl(url));
    }
  }, [currentState, client.videos, states.states])

  const videoRef = useRef();
  const previousUrl = useRef(url);

  const getAudioClip = (clipName) => {
    switch (clipName) {
      case "rain":
        return rain
      case "rain2":
        return rain2
      case "beach":
        return beach
      case "hummingbird":
        return hummingbird
      case "sunnyDay":
        return sunnyDay
      case "waterfall":
        return waterfall
      case "crickets":
        return crickets
      default:
        return
    }
  }

  //the video clip component
  const Clip = ({ url }) => {
    useEffect(() => {
      if (previousUrl.current !== url && videoRef.current) {
        videoRef.current.load();
        previousUrl.current = url;
        let playPromise = videoRef.current.load();

        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
            .catch(error => {
              console.log(error);
            });
        }
      }
    }, [url]);

    return (
      <>
        <video src={url} className='video' ref={videoRef} autoPlay muted loop type='video/mp4' onClick={lastClick}>
        </video>
        <img src={loading} hidden={videoRef.current && videoRef.current.readyState === 4} alt='white circular spinner moving clockwise to indicate video loading'/>
        <audio autoPlay loop>
          <source src={getAudioClip(states.states[currentState].audio)} type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
      </>
    );
  }

  const backgroundRef = useRef();
  const buttonsRef = useRef();

  const initialClick = () => {
    if (beginning) {
      setBeginning(false);
      setCurrentState('forest1');
      setStep(step + 1);
      backgroundRef.current.style.display = "none";
      let playPromise
      if (videoRef.current) {
        videoRef.current.style.display = "block";
        playPromise = videoRef.current.load();
      }

      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }

  //handle the last click before the end screen
  const lastClick = () => {
    if (step === 4) {
      setStep(5)
      setCurrentState("end")
      setUrl("")
      backgroundRef.current.style.display = "block";
    }
  }

  //handle a button click
  const clickHandler = (option) => {
    if (states.states[currentState] !== "" && states.states[currentState] !== "end" && states.states[currentState] !== undefined) {
      setCurrentState(states.states[currentState][option]);
      setStep(step + 1)
      if (option === 'restart') {
        setStep(0)
        setBeginning(true)
        setCurrentState("")
        backgroundRef.current.style.display = "block";
      }
    }
  }

  //component with instructions and buttons
  const InstructionsComponent = ({ showButtons, option1Handler, option2Handler }) =>
    <div className="content">
      <div className="instructions">{states.states[currentState] ? states.states[currentState].description : "Welcome! Click on the trees to take a small mindfulness break through nature."}</div>
      {
        showButtons &&
        <div ref={buttonsRef} className="buttons">
          <button className="button" onClick={() => clickHandler(option1Handler)}>{states.states[currentState] && states.states[currentState].option1Label}</button>
          <button className="button" onClick={() => clickHandler(option2Handler)}>{states.states[currentState] && states.states[currentState].option2Label}</button>
        </div>
      }
    </div>

  //TODO refactor to be more reusable
  const EndInstructionsComponent = ({ donateUrl, optionHandler }) =>
    <div className="content">
      <div className="instructions">have a great day! :)<br />created with ♥ by <a href="https://angelinahan.com" target="blank">Angelina Han</a></div>
      {
        <div ref={buttonsRef} className="buttons">
          <button className="button" onClick={() => clickHandler(optionHandler)}>{states.states[currentState] && states.states[currentState].option1Label}</button>
          <a href={donateUrl} target="blank"><button className="button">{states.states[currentState] && states.states[currentState].option2Label}</button></a>
        </div>
      }
    </div>


  return (
    <div className="App">
      <header className="App-header">
        {
          currentState !== '' && step !== 5 &&
          <>
            <Clip url={url} style={{ display: beginning === true ? 'none' : 'block' }} />
            <span className="pexels-attribution"> Video by {' '}
              <a href={states.states[currentState].url} target="blank"> {states.states[currentState].author}</a> via {' '}
              <a href={"https://www.pexels.com/"} target="blank">Pexels</a>
            </span>
          </>
        }

        <img ref={backgroundRef} src={forest} className="background-image" onClick={() => initialClick()} alt="dark green pine trees against a foggy sky" />
        {(step === 0 || step === 4) &&
          <InstructionsComponent showButtons={false} option1Handler="option1" option2Handler="option2" />
        }
        {
          (step === 1 || step === 2 || step === 3) &&
          <InstructionsComponent showButtons={true} option1Handler="option1" option2Handler="option2" />
        }
        {
          (step === 5) &&
          <EndInstructionsComponent donateUrl="https://ko-fi.com/angelinahan" optionHandler="restart" />
        }
      </header>
    </div>
  );
}

export default App;
